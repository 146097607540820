<template>
  <div class="template-reserve-add">
    <div class="row">
      <div class="col-xl-3 left-card">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <h4 class="mb-4">Add a Reserve</h4>
              <div class="brand mb-3">
                <label for="select-brand">Select Brand</label>
                <b-form-select
                  id="select-brand"
                  :class="{ 'is-invalid': $v.selectedBrand.$error }"
                  v-model="selectedBrand"
                >
                  <b-form-select-option :value="null">
                    Select a brand
                  </b-form-select-option>
                  <b-form-select-option value="addNewBrand">
                    Add a new brand
                  </b-form-select-option>
                  <template v-for="item in equipmentBrands">
                    <b-form-select-option :value="item.value" :key="item.value">
                      {{ item.value }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <span
                  v-if="$v.selectedBrand.$error && !$v.selectedBrand.required"
                  class="help-block invalid-feedback"
                  >Brand is required</span
                >
                <b-input-group class="mt-2" v-if="!addNewBrandInput">
                  <b-form-input
                    v-model="brand"
                    placeholder="Add a brand"
                    :class="{ 'is-invalid': $v.brand.$error }"
                    :disabled="addNewBrandInput"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      text="Button"
                      variant="info"
                      @click="addBrand(brand)"
                      >&#10004;</b-button
                    >
                  </b-input-group-append>
                  <span
                    v-if="$v.brand.$error && !$v.brand.required"
                    class="help-block invalid-feedback"
                    >Brand is required</span
                  >
                </b-input-group>
                <b-alert v-if="brandAddSuccess" variant="success" show>
                  Brand added Successfully!
                </b-alert>
                <b-alert v-if="errorMessageBrand" show variant="danger">
                  Could not add, please retry
                </b-alert>
              </div>
              <!-- end brand -->

              <div class="select mb-3">
                <label for="select-model">Select Model</label>
                <b-form-select
                  id="select-model"
                  :class="{ 'is-invalid': $v.selectedModel.$error }"
                  v-model="selectedModel"
                >
                  <b-form-select-option :value="null"
                    >Select a model</b-form-select-option
                  >
                  <b-form-select-option
                    value="addNewModel"
                    :disabled="disableAddNewModel"
                    >Add a model</b-form-select-option
                  >
                  <template v-for="item in equipmentModelsTable">
                    <b-form-select-option :value="item.id" :key="item.id">
                      {{ item.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <span
                  v-if="$v.selectedModel.$error && !$v.selectedModel.required"
                  class="help-block invalid-feedback"
                  >Model is required</span
                >
                <b-input-group class="mt-2" v-if="!addNewModelInput">
                  <b-form-input
                    v-model="model"
                    placeholder="Add a model"
                    :class="{ 'is-invalid': $v.model.$error }"
                    :disabled="addNewModelInput"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      text="Button"
                      variant="info"
                      @click="addModel(model)"
                      >&#10004;</b-button
                    >
                  </b-input-group-append>
                  <span
                    v-if="$v.model.$error && !$v.model.required"
                    class="help-block invalid-feedback"
                    >Model is required</span
                  >
                </b-input-group>
                <b-alert v-if="modelAddSuccess" variant="success" show>
                  Model added Successfully!
                </b-alert>
                <b-alert v-if="errorMessageModel" show variant="danger">
                  Could not add, please retry
                </b-alert>
              </div>
            </b-form-group>
            <!-- end select-model -->
          </div>
        </div>
      </div>
      <!-- left card -->

      <div class="col-xl-9 right-card">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <h5>Technical Information</h5>
              <b-row align-v="center">
                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-maxLoad">Max Load (in Kgs)</label>
                    <b-input
                      id="input-maxLoad"
                      type="number"
                      placeholder="Enter max load weight"
                      :class="{ 'is-invalid': $v.maxLoad.$error }"
                      v-model.number="maxLoad"
                    ></b-input>
                    <span
                      v-if="$v.maxLoad.$error && !$v.maxLoad.required"
                      class="help-block invalid-feedback"
                      >Max Load is required</span
                    >
                    <span
                      v-if="$v.maxLoad.$error && !$v.maxLoad.numeric"
                      class="help-block invalid-feedback"
                      >Max Load must be numeric</span
                    >
                  </div>
                </b-col>
                <!-- Max Load -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-reserve-weight"
                      >Reserve weight (in Kgs)</label
                    >
                    <b-input
                      id="input-reserve-weight"
                      type="number"
                      placeholder="Enter reserve weight"
                      :class="{ 'is-invalid': $v.reserveWeight.$error }"
                      v-model.number="reserveWeight"
                    ></b-input>
                    <span
                      v-if="
                        $v.reserveWeight.$error && !$v.reserveWeight.required
                      "
                      class="help-block invalid-feedback"
                      >Reserve weight is required</span
                    >
                    <span
                      v-if="
                        $v.reserveWeight.$error && !$v.reserveWeight.numeric
                      "
                      class="help-block invalid-feedback"
                      >Reserve weight must be numeric</span
                    >
                  </div>
                </b-col>
                <!-- Reserve Weight -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-certification">Certification</label>
                    <b-input
                      id="input-select-certification"
                      type="text"
                      placeholder="Enter certification"
                      :class="{ 'is-invalid': $v.selectedCertificate.$error }"
                      v-model="selectedCertificate"
                    ></b-input>
                    <span
                      v-if="
                        $v.selectedCertificate.$error &&
                        !$v.selectedCertificate.required
                      "
                      class="help-block invalid-feedback"
                      >Certification is required</span
                    >
                  </div>
                </b-col>
                <!-- Certification -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-lastPackedBy">Last Packed By</label>
                    <b-input
                      id="input-lastPackedBy"
                      placeholder="Last packed by"
                      :class="{ 'is-invalid': $v.lastPackedBy.$error }"
                      v-model="lastPackedBy"
                    ></b-input>
                    <span
                      v-if="$v.lastPackedBy.$error && !$v.lastPackedBy.required"
                      class="help-block invalid-feedback"
                      >Last packed by is required</span
                    >
                  </div>
                </b-col>
                <!-- Last Packed By -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-lastPackedDate">Last Packed Date</label>
                    <b-form-datepicker
                      id="input-lastPackedDate"
                      :max="maxSelectDate"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                      placeholder="Last packed date"
                      local="en"
                      v-model="$v.lastPackedDate.$model"
                      :class="{ 'is-invalid': $v.lastPackedDate.$error }"
                    ></b-form-datepicker>
                    <span
                      v-if="
                        $v.lastPackedDate.$error && !$v.lastPackedDate.required
                      "
                      class="help-block invalid-feedback"
                      >Last packed Date is required</span
                    >
                  </div>
                </b-col>
                <!-- Last Packed Date -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="input-serial">Serial Number</label>
                    <b-input
                      id="input-serial"
                      placeholder="Enter equipment serial number"
                      :class="{ 'is-invalid': $v.serialNo.$error }"
                      v-model="serialNo"
                    ></b-input>
                    <span
                      v-if="$v.serialNo.$error && !$v.serialNo.required"
                      class="help-block invalid-feedback"
                      >Serial number is required</span
                    >
                  </div>
                </b-col>
                <!-- Equipment Serial number -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="select-manufacture-year"
                      >Year of Manufacture</label
                    >
                    <b-form-select
                      id="select-manufacture-year"
                      :class="{ 'is-invalid': $v.manufactureYear.$error }"
                      v-model="manufactureYear"
                    >
                      <b-form-select-option value="null"
                        >Select one</b-form-select-option
                      >
                      <template v-for="year in yearList">
                        <b-form-select-option :value="year" :key="year">
                          {{ year }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="
                        $v.manufactureYear.$error &&
                        !$v.manufactureYear.required
                      "
                      class="help-block invalid-feedback"
                      >Manufacture year is required</span
                    >
                  </div>
                </b-col>
                <!-- Year of manufacture -->
              </b-row>
            </b-form-group>
            <b-form-group>
              <h5>Operator Linking</h5>
              <b-row align-v="center">
                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-zone">Zone</label>
                    <b-form-select
                      id="select-zone"
                      :class="{ 'is-invalid': $v.selectedZone.$error }"
                      v-model="selectedZone"
                    >
                      <b-form-select-option value="null">
                        Select Zone
                      </b-form-select-option>
                      <template v-for="item in zoneTable">
                        <b-form-select-option :key="item.id" :value="item.id">
                          {{ item.name }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="$v.selectedZone.$error && !$v.selectedZone.required"
                      class="help-block invalid-feedback"
                      >Zone is required</span
                    >
                  </div>
                </b-col>
                <!-- ZONE -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-operator">Operator</label>
                    <b-form-select
                      id="select-operator"
                      :class="{ 'is-invalid': $v.selectedOperator.$error }"
                      v-model="selectedOperator"
                    >
                      <b-form-select-option value="null">
                        Select Operator
                      </b-form-select-option>
                      <template v-for="item in newFilteredOperatorList">
                        <b-form-select-option
                          :key="item.operator_id"
                          :value="item.operator_id"
                        >
                          {{ item.company_name }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="
                        $v.selectedOperator.$error &&
                        !$v.selectedOperator.required
                      "
                      class="help-block invalid-feedback"
                      >Operator is required</span
                    >
                  </div>
                </b-col>
                <!-- OPERATOR -->
              </b-row>
            </b-form-group>
            <b-row align-v="center">
              <b-col sm="12" class="btn-submit">
                <b-button @click="handleSubmit">Submit</b-button>
              </b-col>
              <!-- Submit button -->
            </b-row>
            <b-alert v-if="reserveAddSuccess" variant="success" show>
              Form Submitted Successfully!
            </b-alert>
            <b-alert v-if="errorMessageReserve" show variant="danger">
              Error: Could not add/update
            </b-alert>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- right card -->
    </div>
    <!-- end row -->
  </div>
  <!-- end template-reserve-add -->
</template>

<script>
import {
  GET_EQUIPMENT_BRANDS,
  GET_EQUIPMENT_MODELS_RESERVE,
  GET_ZONES,
  GET_OPERATORS_ADD_PILOT,
  GET_RESERVE_PK,
} from "@/graphql/queries/queries";
import {
  ADD_EQUIPMENT_BRAND,
  ADD_EQUIPMENT_MODEL,
  ADD_RESERVE,
  UPDATE_RESERVE_PK,
} from "@/graphql/mutations/mutations";
import { required, numeric } from "vuelidate/lib/validators";

const BRANDS_ATTRIBUTE = "equipment_brands";
const MODELS_ATTRIBUTE = "equipment_models";
const OPERATORS_ATTRIBUTE = "operators";
const ZONES_ATTRIBUTE = "zone";
const RESERVE_BY_PK_ATTRIBUTE = "reserves_by_pk";

export default {
  name: "addReserve",
  data() {
    return {
      reserveID: this.$route.params.reserveID,
      reserveData: {},
      equipmentBrands: [],
      equipmentModels: [],
      equipmentModelsTable: [],
      zoneTable: [],
      operatorsTable: [],
      newFilteredOperatorList: [],
      certificates: [],
      brand: null,
      model: "",
      maxSelectDate: new Date(),
      addNewBrandInput: true,
      addNewModelInput: true,
      selectedBrand: null,
      selectedModel: null,
      selectedCertificate: null,
      selectedZone: null,
      selectedOperator: null,
      serialNo: "",
      lastPackedBy: "",
      lastPackedDate: null,
      maxLoad: null,
      reserveWeight: null,
      manufactureYear: null,
      yearList: [],
      brandAddSuccess: false,
      modelAddSuccess: false,
      disableAddNewModel: true,
      errorMessageBrand: false,
      errorMessageModel: false,
      reserveAddSuccess: false,
      errorMessageReserve: false,
    };
  },
  validations: {
    selectedBrand: {
      required,
    },
    brand: {
      required: function () {
        return this.addNewBrandInput || !!this.brand;
      },
    },
    selectedModel: {
      required,
    },
    model: {
      required: function () {
        return this.addNewModelInput || !!this.model;
      },
    },
    selectedCertificate: {
      required,
    },
    selectedZone: {
      required,
    },
    selectedOperator: {
      required,
    },
    serialNo: {
      required,
    },
    lastPackedBy: {
      required,
    },
    lastPackedDate: {
      required,
    },
    reserveWeight: {
      required,
      numeric,
    },
    maxLoad: {
      required,
      numeric,
    },
    manufactureYear: {
      required,
    },
    form: [
      "selectedBrand",
      "brand",
      "model",
      "selectedModel",
      "selectedCertificate",
      "selectedZone",
      "selectedOperator",
      "serialNo",
      "lastPackedBy",
      "lastPackedDate",
      "reserveWeight",
      "maxLoad",
      "manufactureYear",
    ],
  },
  watch: {
    reserveData() {
      (this.selectedCertificate = this.reserveData.certification),
        (this.reserveWeight = this.reserveData.reserve_weight),
        (this.lastPackedBy = this.reserveData.last_packed_by),
        (this.lastPackedDate = this.reserveData.last_packing_date),
        (this.serialNo = this.reserveData.serial_no),
        (this.maxLoad = this.reserveData.max_load),
        (this.manufactureYear = this.reserveData.year_mfg),
        (this.selectedBrand = this.reserveData.equipment_model.brand),
        (this.selectedZone = this.reserveData.operator.zone.id);
      this.selectedOperator = this.reserveData.operator_id;
      this.filterModels();
      this.filterOperatorsTable();
    },
    selectedBrand() {
      this.filterModels();
    },
    selectedModel() {
      this.addNewModelInput = this.selectedModel !== "addNewModel";
    },
    selectedZone() {
      this.filterOperatorsTable();
    },
  },
  mounted() {
    for (let i = 1970; i <= new Date().getFullYear(); i++) {
      this.yearList.unshift(i);
    }
    this.$apollo.queries.reserveData.skip = true;
    this.loadEditDatas();
  },
  methods: {
    async handleSubmit() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      if (isValid) {
        this.errorMessageReserve = false;
        const payLoad = {
          certification: this.selectedCertificate,
          reserve_weight: this.reserveWeight,
          last_packed_by: this.lastPackedBy,
          last_packing_date: this.lastPackedDate,
          serial_no: this.serialNo,
          max_load: this.maxLoad,
          year_mfg: this.manufactureYear,
          model_id: this.selectedModel,
          operator_id: this.selectedOperator,
        };
        if (this.reserveID) {
          // update reserve
          try {
            const updateReserveMutation = await this.$apollo.mutate({
              mutation: UPDATE_RESERVE_PK,
              variables: {
                id: this.reserveID,
                input: payLoad,
              },
            });
            if (updateReserveMutation) {
              this.reserveAddSuccess = true;
              this.formReset();
              console.log(
                "Reserve updated successfully",
                updateReserveMutation
              );
              setTimeout(() => {
                this.$router.push({ name: "reserves" });
              }, 3000);
            }
          } catch (error) {
            this.errorMessageReserve = true;
            console.error(error);
          }
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
        } else {
          // add new reserve
          try {
            const addReserveMutation = await this.$apollo.mutate({
              mutation: ADD_RESERVE,
              variables: {
                input: payLoad,
              },
            });
            if (addReserveMutation) {
              this.reserveAddSuccess = true;
              this.formReset();
              console.log("Reserve added successfully", addReserveMutation);
              setTimeout(() => {
                this.$router.push({ name: "reserves" });
              }, 3000);
            }
          } catch (error) {
            this.errorMessageReserve = true;
            console.error(error);
          }
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
        }
      }
    },
    formReset() {
      (this.selectedBrand = null),
        (this.selectedZone = null),
        (this.selectedCertificate = null),
        (this.maxLoad = null),
        (this.lastPackedBy = null),
        (this.lastPackedDate = null),
        (this.serialNo = ""),
        (this.reserveWeight = ""),
        (this.manufactureYear = null),
        (this.selectedModel = null),
        (this.selectedOperator = null);
    },
    filterModels() {
      this.selectedModel = null;
      this.addNewBrandInput = true;
      this.addNewModelInput = true;
      this.disableAddNewModel = true;
      if (this.selectedBrand !== null) {
        this.disableAddNewModel = false;
      }
      if (this.selectedBrand === "addNewBrand") {
        this.addNewBrandInput = false;
      }
      this.equipmentModelsTable = this.equipmentModels.filter(
        (a) => a.brand === this.selectedBrand
      );
      if (this.reserveID) {
        // for reserve update
        this.selectedModel = this.reserveData.equipment_model.id;
      }
    },
    filterOperatorsTable() {
      const newOperatorsTable = this.operatorsTable.filter(
        (a) => a.zone.id === this.selectedZone
      );
      this.newFilteredOperatorList = newOperatorsTable;
      if (this.reserveID) {
        // for reserve update
        this.selectedOperator = this.reserveData.operator_id;
      }
    },
    async addBrand(brandName) {
      this.errorMessageBrand = false;
      this.$v.form.brand.$touch();
      let isValid = !this.$v.form.brand.$invalid;
      if (isValid) {
        try {
          const addBrand = await this.$apollo.mutate({
            mutation: ADD_EQUIPMENT_BRAND,
            variables: {
              input: { value: brandName },
            },
          });
          if (addBrand) {
            this.brandAddSuccess = true;
            this.$apollo.queries.equipmentBrands.refetch();
            this.selectedBrand = brandName;
            this.brand = "";
            console.log("brand added successfully", addBrand);
          }
        } catch (error) {
          this.errorMessageBrand = true;
          console.error(error);
        }
      }
    },
    async addModel(modelName) {
      this.errorMessageModel = false;
      this.$v.form.model.$touch();
      let isValid = !this.$v.form.model.$invalid;
      if (isValid) {
        try {
          console.log(modelName);
          const addModel = await this.$apollo.mutate({
            mutation: ADD_EQUIPMENT_MODEL,
            variables: {
              input: {
                name: modelName,
                brand: this.selectedBrand,
                equipment_type: "reserve",
              },
            },
          });
          if (addModel) {
            this.modelAddSuccess = true;
            const refreshModelList = await this.$apollo.queries.equipmentModels.refetch();
            if (refreshModelList) {
              this.filterModels();
              const addedModel = this.equipmentModelsTable.find(
                (a) => a.name === modelName
              );
              if (addedModel) {
                this.selectedModel = addedModel.id;
                this.model = "";
              }
            }
            console.log("Model added successfully", addModel);
          }
        } catch (error) {
          this.errorMessageModel = true;
          console.error(error);
        }
      }
    },
    async loadEditDatas() {
      if (this.reserveID) {
        let modelsDataReady = await this.$apollo.queries.equipmentModels.refetch();
        let operatorsDataReady = await this.$apollo.queries.operatorsTable.refetch();
        if (modelsDataReady && operatorsDataReady) {
          this.$apollo.queries.reserveData.skip = false;
          this.$apollo.queries.reserveData.refetch();
        }
      }
    },
  },
  apollo: {
    equipmentBrands: {
      // graphql query
      query: GET_EQUIPMENT_BRANDS,
      update(data) {
        return data[BRANDS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    equipmentModels: {
      // graphql query
      query: GET_EQUIPMENT_MODELS_RESERVE,
      update(data) {
        return data[MODELS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    operatorsTable: {
      // graphql query
      query: GET_OPERATORS_ADD_PILOT,
      update(data) {
        return data[OPERATORS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    reserveData: {
      // graphql query
      query: GET_RESERVE_PK,
      variables() {
        return {
          id: this.reserveID,
        };
      },
      skip: true,
      update(data) {
        return data[RESERVE_BY_PK_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.template-reserve-add {
  padding-top: 24px;

  .left-card {
    h5 {
      text-align: center;
    }
  }

  .right-card {
    .weight-min-max {
      margin-left: 0;

      .to-min-max {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .btn-submit {
      text-align: right;

      button {
        background: rgb(114, 124, 245);
        border: none;
      }
    }
  }
}
</style>
